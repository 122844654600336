
const storePostMemberTravel = (value) => {
  return {
  type: 'POST_CREATE_MEMBER_TRAVEL',
    value
  }
};

const createMemberTravel = (axios, endpoint, dataSend) => {
  return (dispatch) => {
    axios.post(`${endpoint}travel/`, dataSend).then(({data}) => {
      return dispatch(storePostMemberTravel(data))
    }).catch((err) => {})
  };
}

// const editMemberTravel = (axios, endpoint, dataSend) => {
  // return (dispatch) => {
  //   axios.post(`${endpoint}travel/`, dataSend).then(({data}) => {
  //     return dispatch(storePostMemberTravel(data))
  //   }).catch((err) => {})
  // };
// }

module.exports={
  createMemberTravel,
}