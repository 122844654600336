
const storePostMemberPersonal = (value) => {
  return {
  type: 'POST_CREATE_MEMBER_PERSONAL',
    value
  }
};
const storeGetDataAllUser = (value) => {
  return {
  type: 'GET_DATA_ALL_USER',
    value
  }
};
const storeGetAdminDataAllUser = (value) => {
  return {
  type: 'GET_DATA_ADMIN_USER',
    value
  }
};
const storeGetDataAllUserTravel = (value) => {
  return {
  type: 'GET_DATA_ALL_USER_TRAVEL',
    value
  }
};
const storeGetDataUserfromQr = (value) => {
  return {
  type: 'GET_DATA_USER_FROM_QR',
    value
  }
};

//! Travel
const getDataAllUserTravel = (axios, endpoint, dataSend) => {
  // console.log(dataSend)
  return (dispatch) => {
    axios.get(`${endpoint}travel/all`).then(({data}) => {
      return dispatch(storeGetDataAllUserTravel(data))
    }).catch((err) => {console.log(err)})
  }
}

//! User / Personal
//* Create User Jamaah or Admin ...
const createUserPersonal = (axios, endpoint, dataSend) => {
  return (dispatch) => {
    axios.post(`${endpoint}user`, dataSend, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({data}) => {
      return dispatch(storePostMemberPersonal(data))
    }).catch((err) => {})
  };
}

//* Change User from another Travel ...
const changeUserToAnotherTravel = (axios, endpoint, dataSend, id) => {
  console.log('Masuk')
  return (dispatch) => {
    axios.post(`${endpoint}user/edit/${id}`, dataSend, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({data}) => {
      console.log(data)
      return dispatch(storePostMemberPersonal(data))
    }).catch((err) => {})
  };
}

const getDataUserWhere = (axios, endpoint, dataSend) => {
  return (dispatch) => {
    // console.log(dataSend)
    axios.post(`${endpoint}user/getdatawhere`, dataSend).then(({data}) => {
      // console.log(data)
      if (dataSend.type !== "jama'ah") {
        return dispatch(
          storeGetAdminDataAllUser(data.data)
        )
      } else {
        return dispatch(
          storeGetDataAllUser(data.data)
        )
      }
    }).catch((err) => {console.log(err)})
  }
}

const getDayaUserWhere = (axios, endpoint, dataSend) => {
  return (dispatch) => {
    axios.post(`${endpoint}user/getdatajamaahforqr`, dataSend).then(({data}) => {
        return dispatch(
          storeGetDataUserfromQr(data.data)
        )
    }).catch((err) => {console.log(err)})
  }
}



module.exports={
  createUserPersonal,
  changeUserToAnotherTravel,
  getDataUserWhere,
  getDataAllUserTravel,
  getDayaUserWhere,
}