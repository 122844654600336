import React, { Component } from 'react';
import { connect } from 'react-redux'
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import { checkLocalStorage } from './actions/index'
import './App.scss';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const ViewUserQr = React.lazy(() => import('./views/Pages/ViewUserQr'))
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusLogin: false
    }
  }

  render() {
    return (
      <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/viewuserqr/:id" name="viewuserqr" render={props => <ViewUserQr {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              {
                this.state.statusLogin ? <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} /> :
                <Route path="/" name="Login Page" render={props => <Login {...props}/>} />
              }
              {/* <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} /> */}
              
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }

  componentDidMount() {
    this.props.checkLocalStorage()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.localstoragedata !== this.props.localstoragedata) {
      if (nextProps.localstoragedata && nextProps.localstoragedata.travel_email) {
        this.setState({statusLogin: true})
      } else {
        this.setState({statusLogin: false})
      }
    }
  }
}

const mapState = state => {
  return {
    localstoragedata: state.hajjTracker.localstoragedata,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkLocalStorage: () => dispatch(checkLocalStorage()),
  }
}

export default connect(mapState, mapDispatchToProps)(App);
