import axios from 'axios'
import * as jwtJsDecode from 'jwt-decode';
import userHit from './userHit'
import memberHit from './memberTravel'
import memberUser from './memberUser'
import itinerary from './itinerary'
import article from './article'
import maps from './maps'
// const endpoint = 'http://localhost:3003/api/v1/'
// const endpoint = 'http://192.168.214.234:3000/'
// const endpoint = 'http://45.76.182.64:3001/api/'
// const endpoint = 'http://localhost:3000/'
const endpoint = 'https://103.157.97.98/teman-hidup-api/'

export const checkLocalStorage = () => {return userHit.checkLocalStorage(jwtJsDecode)}
export const login = (data) => {return userHit.login(axios, endpoint, jwtJsDecode, data)}
export const getDayaUserWhere = (data) => {return memberUser.getDayaUserWhere(axios, endpoint, data)}
export const clearLocalStorage = (data) => {return userHit.clearLocalStorage(jwtJsDecode)}
export const createMemberTravel = (data) => {return memberHit.createMemberTravel(axios, endpoint, data)}
export const editMemberTravel = (data) => {return memberHit.editMemberTravel(axios, endpoint, data)}
export const createUserPersonal = (data) => {return memberUser.createUserPersonal(axios, endpoint, data)}
export const changeUserToAnotherTravel = (data, id) => {return memberUser.changeUserToAnotherTravel(axios, endpoint, data, id)}
export const getDataUserWhere = (data) => {return memberUser.getDataUserWhere(axios, endpoint, data)}
export const getDataAllUserTravel = () => {return memberUser.getDataAllUserTravel(axios, endpoint)}
export const getDataItineraryTravel = (data) => {return itinerary.getDataItineraryTravel(axios, endpoint, data)}
export const getOneDataItineraryTravel = (data) => {return itinerary.getOneDataItineraryTravel(axios, endpoint, data)}
export const createDataItineraryTravel = (data) => {return itinerary.createDataItineraryTravel(axios, endpoint, data)}
export const editDataItineraryTravel = (data) => {return itinerary.editDataItineraryTravel(axios, endpoint, data)}
export const deleteDataItineraryTravel = (data) => {return itinerary.deleteDataItineraryTravel(axios, endpoint, data)}
export const addPersonForBatchItTravel = (data) => {return itinerary.addPersonForBatchItTravel(axios, endpoint, data)}
export const getDataTableArticle = (data) => {return article.getDataTableArticle(axios, endpoint, data)}
export const getOneDataArticle = (data) => {return article.getOneDataArticle(axios, endpoint, data)}
export const createDataArticle = (data) => {return article.createDataArticle(axios, endpoint, data)}
export const editDataArticle = (data) => {return article.editDataArticle(axios, endpoint, data)}
export const getDataCount = (data) => {return article.getDataCount(axios, endpoint, data)}
export const getDataLocationAllJamaah = (data) => {return maps.getDataLocationAllJamaah(axios, endpoint, data)}