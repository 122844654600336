
const storeGetDataItineraryTravel = (value) => {
  return {
  type: 'GET_ITINERARY_TRAVEL',
    value
  }
};

const storeGetOneDataItineraryTravel = (value) => {
  return {
  type: 'GET_ONE_ITINERARY_TRAVEL',
    value
  }
};

const createOneDataItineraryTravel = (value) => {
  return {
  type: 'CREATE_ONE_ITINERARY_TRAVEL',
    value
  }
};

const deleteOneDataItineraryTravel = (value) => {
  return {
  type: 'DELETE_ONE_ITINERARY_TRAVEL',
    value
  }
};

const addPersonOneDataItineraryTravel = (value) => {
  return {
  type: 'ADD_PERSON_ONE_ITINERARY_TRAVEL',
    value
  }
};

//! Travel
const getDataItineraryTravel = (axios, endpoint, dataSend) => {
  return (dispatch) => {
    axios.get(`${endpoint}itinerarry/getallparentbytravel/${dataSend}`).then(({data}) => {
      return dispatch(storeGetDataItineraryTravel(data))
    }).catch((err) => {console.log(err)})
  }
}

const getOneDataItineraryTravel = (axios, endpoint, dataSend) => {
  return (dispatch) => {
    axios.get(`${endpoint}itinerarry/getoneitbytravel/${dataSend}`).then(({data}) => {
      return dispatch(storeGetOneDataItineraryTravel(data))
    }).catch((err) => {console.log(err)})
  }
}

const createDataItineraryTravel = (axios, endpoint, dataSend) => {
  return (dispatch) => {
    axios.post(`${endpoint}itinerarry`, dataSend).then(({data}) => {
      return dispatch(createOneDataItineraryTravel(data))
    }).catch((err) => {console.log(err)})
  }
}

const editDataItineraryTravel = (axios, endpoint, dataSend) => {
  return (dispatch) => {
    axios.post(`${endpoint}itinerarry/getoneitbytravel/edit`, dataSend).then(({data}) => {
      return dispatch(createOneDataItineraryTravel(data))
    }).catch((err) => {console.log(err)})
  }
}

const deleteDataItineraryTravel = (axios, endpoint, dataSend) => {
  return (dispatch) => {
    // console.log(`${endpoint}getoneitbytravel/delete/${dataSend.id}`)
    axios.get(`${endpoint}itinerarry/getoneitbytravel/delete/${dataSend.id}`).then(({data}) => {
      return dispatch(deleteOneDataItineraryTravel(data))
    }).catch((err) => {console.log(err)})
  }
}

const addPersonForBatchItTravel = (axios, endpoint, dataSend) => {
  return (dispatch) => {
    axios.post(`${endpoint}itinerarry/getoneitbytravel/addperson`, dataSend).then(({data}) => {
      return dispatch(addPersonOneDataItineraryTravel(data))
    }).catch((err) => {console.log(err)})
  }
}


module.exports={
  getDataItineraryTravel,
  getOneDataItineraryTravel,
  createDataItineraryTravel,
  editDataItineraryTravel,
  deleteDataItineraryTravel,
  addPersonForBatchItTravel,
}