const storeGetDataLocationAllJamaah = (value) => {
  return {
  type: 'GET_DATA_JAMAAH_LOCATION',
    value
  }
};

const getDataLocationAllJamaah = (axios, endpoint, dataSend) => {
  return (dispatch) => {
    axios.post(`${endpoint}user/getUserByBtchAndActLoc`, dataSend).then(({data}) => {
      console.log(data)
      return dispatch(storeGetDataLocationAllJamaah(data.data))
    }).catch((err) => {console.log(err)})
  }
}

module.exports={
  getDataLocationAllJamaah,
} 