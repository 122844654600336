
const storeGetDataArticle = (value) => {
  return {
  type: 'GET_SORT_ALL_ARTICLE',
    value
  }
};

const storeGetDataOneArticle = (value) => {
  return {
  type: 'GET_ARTICLE',
    value
  }
};

const storePostArticle = (value) => {
  return {
  type: 'SAVE_ARTICLE',
    value
  }
};

const storeGetCountArticle = (value) => {
  return {
  type: 'COUNT_ARTICLE',
    value
  }
};

const getDataTableArticle = (axios, endpoint, dataSend) => {
  return (dispatch) => {
    axios.get(`${endpoint}article/getallshortarticle`).then(({data}) => {
      return dispatch(storeGetDataArticle(data.data))
    }).catch((err) => {console.log(err)})
  }
}

const getOneDataArticle = (axios, endpoint, dataSend) => {
  return (dispatch) => {
    axios.post(`${endpoint}article/getonefullarticle`, {article_id: dataSend}).then(({data}) => {
      return dispatch(storeGetDataOneArticle(data.data))
    }).catch((err) => {console.log(err)})
  }
}

const createDataArticle = (axios, endpoint, dataSend) => {
  return (dispatch) => {
    axios.post(`${endpoint}article`, dataSend, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({data}) => {
      return dispatch(storePostArticle(data))
    }).catch((err) => {})
  }
}

const editDataArticle = (axios, endpoint, dataSend) => {
  return (dispatch) => {
    axios.post(`${endpoint}article/edit`, dataSend, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({data}) => {
      return dispatch(storePostArticle(data))
    }).catch((err) => {})
  }
}

const getDataCount = (axios, endpoint, dataSend) => {
  return (dispatch) => {
    axios.post(`${endpoint}article/countingReadAndTotalArticle`, {travel_id: dataSend}).then(({data}) => {
      return dispatch(storeGetCountArticle(data))
    }).catch((err) => {})
  }
}

module.exports={
  getDataTableArticle,
  getOneDataArticle,
  createDataArticle,
  editDataArticle,
  getDataCount,
}