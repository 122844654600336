import { combineReducers } from 'redux';

const dataHajjTracker = {
    localstoragedata: null,
    indicatorTravelSave: null,
    indicatorUserSave: null,
    getDataUserAll: null,
    getDataUserTravelAll: null,
    getDataAllPrnItByTravel: [],
    getOneDataAllPrnItByTravel: [],
    successCreateItineraryTravel: null,
    successDeleteItineraryTravel: null,
    successAddPersonItineraryTravel: null,
    getDataUserAdmin: null,
    getSortAllArticle: null,
    getArticle: null,
    saveArticle: null,
    countArticle: null,
    getDataJamaahLocation: [],
    getDataJamaahFromQr: {}
  }
  
  const hajjTrackerReducers = (state = dataHajjTracker, action) => {
    switch (action.type) {
      case 'GET_LOCAL_STORAGE':
        return { ...state, localstoragedata: action.value }
      case 'POST_CREATE_MEMBER_TRAVEL':
        return { ...state, indicatorTravelSave: action.value }
      case 'POST_CREATE_MEMBER_PERSONAL':
        return { ...state, indicatorUserSave: action.value }
      case 'GET_DATA_ALL_USER':
        return { ...state, getDataUserAll: action.value }
      case 'GET_DATA_ALL_USER_TRAVEL':
        return { ...state, getDataUserTravelAll: action.value }
      case 'GET_ITINERARY_TRAVEL':
        return { ...state, getDataAllPrnItByTravel: action.value }
      case 'GET_ONE_ITINERARY_TRAVEL':
        return { ...state, getOneDataAllPrnItByTravel: action.value }
      case 'CREATE_ONE_ITINERARY_TRAVEL':
        return { ...state, successCreateItineraryTravel: action.value }
      case 'DELETE_ONE_ITINERARY_TRAVEL':
        return { ...state, successDeleteItineraryTravel: action.value }
      case 'ADD_PERSON_ONE_ITINERARY_TRAVEL':
        return { ...state, successAddPersonItineraryTravel: action.value }
      case 'GET_DATA_ADMIN_USER':
        return { ...state, getDataUserAdmin: action.value }
      case 'GET_SORT_ALL_ARTICLE':
        return { ...state, getSortAllArticle: action.value }
      case 'GET_ARTICLE':
        return { ...state, getArticle: action.value }
      case 'SAVE_ARTICLE':
        return { ...state, saveArticle: action.value }
      case 'COUNT_ARTICLE':
        return { ...state, countArticle: action.value }
      case 'GET_DATA_JAMAAH_LOCATION':
        return { ...state, getDataJamaahLocation: action.value }
      case 'GET_DATA_USER_FROM_QR':
        return { ...state, getDataJamaahFromQr: action.value }
      default:
        return state;
    }
  };
  
  export default combineReducers({
    hajjTracker: hajjTrackerReducers,
  });