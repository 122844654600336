
const getLocalStorage = (value) => {
  return {
  type: 'GET_LOCAL_STORAGE',
    value
  }
};

const checkLocalStorage = (jwtDecode) => {
  return (dispatch) => {
    const data = localStorage.getItem('data')
    if (data) {
      const dataParse = JSON.parse(data)
      // let decoded = jwtDecode.jwtDecode(data)
      // console.log(decoded.payload)
      return dispatch(getLocalStorage(dataParse))
    } else {
      return dispatch(getLocalStorage(null))
    }
  };
}

const login = (axios, endpoint, jwtJsDecode, dataSend) => {
  return (dispatch) => {
    axios.post(`${endpoint}user/login`, dataSend).then(({data}) => {
      console.log(data.data)
      if (data.status) {
        localStorage.setItem('data', JSON.stringify(data.data))
        //! decode if using jwtdecode
        // let decoded = jwtJsDecode.jwtDecode(data.token)
        return dispatch(getLocalStorage(data.data))
      } else {
        alert('Password Tidak Tepat')
      }
    }).catch((err) => {
      console.log(err)
    })
  };
}

const clearLocalStorage = (jwtDecode) => {
  return (dispatch) => {
    localStorage.clear();
    return dispatch(checkLocalStorage(jwtDecode))
  }
}

module.exports={
  checkLocalStorage,
  login,
  clearLocalStorage
}